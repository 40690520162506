import { FormProvider, useForm } from "react-hook-form"
import React, { useContext, useEffect, useMemo } from 'react'
import { gql, useMutation, useQuery } from '@apollo/client'

import AssetsDialog from '../../components/assets/dialog'
import AutoSave from '../../components/autosave'
import Errors from '../../components/errors'
import Fieldset from '../../components/form/fieldset'
import Input from '../../components/form/input'
import Layout from '../../components/layout'
import LayoutCompass from '../../components/layout/compass'
import LayoutContent from '../../components/layout/content'
import Metafields from '../../components/form/partials/meta-fields'
import NotificationContext from '../../components/notifications/context'
import RecordPublishButton from '../../components/record/publish-button'
import RecordSchedule from '../../components/record/schedule'
import RecordTimestamps from '../../components/record/timestamps'
import RecordUnpublishButton from '../../components/record/unpublish-button'
import SectionEditingStatus from '../../components/record/editing-status'
import Slug from '../../components/form/slug'
import StatusBadge from '../../components/status/badge'
import Textarea from '../../components/form/textarea'
import categoryFragment from '../../fragments/category'
import { requireAuthenticatedEditor } from '../../services/authentication'
import { useTitle } from '../../components/browser'

const GET_CATEGORY_QUERY = gql`
  query GetCategory($id: ID!) {
    category(id: $id) {
      ${categoryFragment}
    }
  }
`

const UPDATE_CATEGORY_MUTATION = gql`
  mutation UpdateCategoryMutation($id: ID!, $titleDe: String, $titleEn: String, $slugDe: String, $slugEn: String, $teaserDe: String, $teaserEn: String, $coverImage: ID, $metaTitleDe: String, $metaTitleEn: String, $metaDescriptionDe: String, $metaDescriptionEn: String, $keywordsDe: String, $keywordsEn: String ) {
    updateCategory(input: { id: $id, titleDe: $titleDe, titleEn: $titleEn, slugDe: $slugDe, slugEn: $slugEn, teaserDe: $teaserDe, teaserEn: $teaserEn, coverImage: $coverImage, metaTitleDe: $metaTitleDe, metaTitleEn: $metaTitleEn, metaDescriptionDe: $metaDescriptionDe, metaDescriptionEn: $metaDescriptionEn, keywordsDe: $keywordsDe, keywordsEn: $keywordsEn }) {
      category {
        ${categoryFragment}
      }
    }
  }
`
export default function CategoryForm({ id }) {
  requireAuthenticatedEditor()

  const { addNotification } = useContext(NotificationContext)
  const methods = useForm()
  const { loading, error, data } = useQuery(GET_CATEGORY_QUERY,
    {
      variables: { id },
      onCompleted: (data) => methods.reset(data.category)
    }
  )
  const [mutate, { loading: mutating }] = useMutation(UPDATE_CATEGORY_MUTATION, {
    onError: () => {
      addNotification({ title: 'Error updating category', message: 'The category could not be updated.', type: 'error' })
    }
  })

  const title = useMemo(() => `${data?.category?.titleDe} - Category`, [data])
  useTitle(title)

  if (error) {
    console.error(error);
    return <div>There was an error loading the category.</div>
  }

  const onSubmit = () => {}

  return (
    <Layout>
      <LayoutCompass
        title={title}
        backTo='/categories'
      />
      <LayoutContent loading={loading}>
        <FormProvider {...methods}>
          <div className="flex justify-between space-x-6">
            <div className="bg-white rounded-lg shadow flex-1 p-6">
              <form onSubmit={methods.handleSubmit(onSubmit)} className="flex flex-col space-y-8 flex-1">
                <Errors errors={methods.formState.errors}/>
                <Fieldset>
                  <Input
                    label="Title - German"
                    name="titleDe"
                    register={methods.register}
                  />
                  <Input
                    label="Title- English"
                    name="titleEn"
                    register={methods.register}
                  />
                </Fieldset>
                <Fieldset>
                  <Slug
                    label="Slug - German"
                    name="slugDe"
                    control={methods.control}
                  />
                  <Slug
                    label="Slug - English"
                    name="slugEn"
                    ccontrol={methods.control}
                  />
                </Fieldset>
                <Fieldset>
                  <Textarea
                    label="Teaser - German"
                    name="teaserDe"
                    control={methods.control}
                  />
                  <Textarea
                    label="Teaser - English"
                    name="teaserEn"
                    control={methods.control}
                  />
                </Fieldset>
                <Fieldset>
                  <AssetsDialog
                    label='Cover Image'
                    value={data?.category?.coverImage}
                    methods={methods}
                    name="coverImage.id"
                  />
                </Fieldset>
                <Metafields methods={methods}/>
              </form>
            </div>
            <AutoSave
              id={id}
              mutate={mutate}
              control={methods.control}
            />
            <div className="w-3/12 bg-white shadow rounded-lg sticky top-24 self-start space-y-4 p-6 text-gray-500">
              <div className="flex justify-between items-center">
                <div className="font-bold">Status</div>
                <StatusBadge status={data?.category?.editingStatus}/>
              </div>
              <RecordPublishButton
                record={data?.category}
                label="category"
                methods={methods}
              />
              <RecordUnpublishButton
                record={data?.category}
                label="category"
                methods={methods}
              />
              <SectionEditingStatus
                isDirty={methods.formState.isDirty}
                mutating={mutating}
              />
              <RecordTimestamps
                record={data?.category}
              />
            </div>
          </div>
        </FormProvider>
      </LayoutContent>
    </Layout>
  )
}
